import { useEffect, useState } from "react";
import { APIURL } from "../config";
import { toast } from "react-toastify";
import PartlyApi from "../services/PartlyApi";

const useCountriesFetch = (reload) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await PartlyApi.get(
          APIURL + "/admin/countries"
        );

        setData(response.data);
        setLoading(false);
      } catch (error) {
        if (error.message) {
          toast.error(error.message);
        }
        setLoading(false);
      }
    };

    fetchData();
  }, [reload]);

  return { loading, setLoading, data, setData };
};

export default useCountriesFetch;
