import React, { useEffect, useState } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import Layout from "./layouts/Layout";
import "react-toastify/dist/ReactToastify.min.css";
import "react-datetime/css/react-datetime.css";
import "react-image-lightbox/style.css";
import { LoginContext } from "./context/Context";
import { DEFAULT_LOCALE } from "./config";

const App = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  /**
   * Upon loading the app, make sure that the default locale is set to EN.
   */
  useEffect(() => {
    localStorage.setItem('locale-admin', DEFAULT_LOCALE);
  }, []);

  return (
    <Router basename={process.env.PUBLIC_URL}>
      <LoginContext.Provider
        value={{
          email,
          setEmail,
          password,
          setPassword
        }}
      >
        <Layout />
      </LoginContext.Provider>
    </Router>
  );
};

export default App;
