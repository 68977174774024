import moment from "moment";
import { faInfinity } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Badge from "reactstrap/es/Badge";
import React from "react";

export const isIterableArray = (array) =>
  Array.isArray(array) && !!array.length;

//===============================
// Store
//===============================
export const getItemFromStore = (key, defaultValue, store = localStorage) =>
  JSON.parse(store.getItem(key)) || defaultValue;
export const setItemToStore = (key, payload, store = localStorage) =>
  store.setItem(key, JSON.stringify(payload));
export const getStoreSpace = (store = localStorage) =>
  parseFloat(
    (
      escape(encodeURIComponent(JSON.stringify(store))).length /
      (1024 * 1024)
    ).toFixed(2)
  );

//===============================
// Moment
//===============================
export const getDuration = (startDate, endDate) => {
  if (!moment.isMoment(startDate))
    throw new Error(
      `Start date must be a moment object, received ${typeof startDate}`
    );
  if (endDate && !moment.isMoment(endDate))
    throw new Error(
      `End date must be a moment object, received ${typeof startDate}`
    );

  return `${startDate.format("ll")} - ${
    endDate ? endDate.format("ll") : "Present"
  } • ${startDate.from(endDate || moment(), true)}`;
};

export const numberFormatter = (number, fixed = 2) => {
  // Nine Zeroes for Billions
  return Math.abs(Number(number)) >= 1.0e9
    ? (Math.abs(Number(number)) / 1.0e9).toFixed(fixed) + "B"
    : // Six Zeroes for Millions
    Math.abs(Number(number)) >= 1.0e6
    ? (Math.abs(Number(number)) / 1.0e6).toFixed(fixed) + "M"
    : // Three Zeroes for Thousands
    Math.abs(Number(number)) >= 1.0e3
    ? (Math.abs(Number(number)) / 1.0e3).toFixed(fixed) + "K"
    : Math.abs(Number(number)).toFixed(fixed);
};

//===============================
// Colors
//===============================
export const hexToRgb = (hexValue) => {
  let hex;
  hexValue.indexOf("#") === 0
    ? (hex = hexValue.substring(1))
    : (hex = hexValue);
  // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
  const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(
    hex.replace(shorthandRegex, (m, r, g, b) => r + r + g + g + b + b)
  );
  return result
    ? [
        parseInt(result[1], 16),
        parseInt(result[2], 16),
        parseInt(result[3], 16),
      ]
    : null;
};

export const rgbColor = (color = colors[0]) => `rgb(${hexToRgb(color)})`;
export const rgbaColor = (color = colors[0], alpha = 0.5) =>
  `rgba(${hexToRgb(color)},${alpha})`;

export const colors = [
  "#2c7be5",
  "#00d97e",
  "#e63757",
  "#39afd1",
  "#fd7e14",
  "#02a8b5",
  "#727cf5",
  "#6b5eae",
  "#ff679b",
  "#f6c343",
];

export const themeColors = {
  primary: "#2c7be5",
  secondary: "#748194",
  success: "#4258CF",
  info: "#27bcfd",
  warning: "#f5803e",
  danger: "#e63757",
  light: "#f9fafd",
  dark: "#0b1727",
};

export const grays = {
  white: "#fff",
  100: "#f9fafd",
  200: "#edf2f9",
  300: "#d8e2ef",
  400: "#b6c1d2",
  500: "#9da9bb",
  600: "#748194",
  700: "#5e6e82",
  800: "#4d5969",
  900: "#344050",
  1000: "#232e3c",
  1100: "#0b1727",
  black: "#000",
};

export const darkGrays = {
  white: "#fff",
  1100: "#f9fafd",
  1000: "#edf2f9",
  900: "#d8e2ef",
  800: "#b6c1d2",
  700: "#9da9bb",
  600: "#748194",
  500: "#5e6e82",
  400: "#4d5969",
  300: "#344050",
  200: "#232e3c",
  100: "#0b1727",
  black: "#000",
};

export const getGrays = (isDark) => (isDark ? darkGrays : grays);

export const rgbColors = colors.map((color) => rgbColor(color));
export const rgbaColors = colors.map((color) => rgbaColor(color));

//===============================
// Echarts
//===============================
export const getPosition = (pos, params, dom, rect, size) => ({
  top: pos[1] - size.contentSize[1] - 10,
  left: pos[0] - size.contentSize[0] / 2,
});

//===============================
// E-Commerce
//===============================
export const calculateSale = (base, less = 0, fix = 2) =>
  (base - base * (less / 100)).toFixed(fix);
export const getTotalPrice = (cart, baseItems) =>
  cart.reduce((accumulator, currentValue) => {
    const { id, quantity } = currentValue;
    const { price, sale } = baseItems.find((item) => item.id === id);
    return accumulator + calculateSale(price, sale) * quantity;
  }, 0);

//===============================
// Helpers
//===============================
export const getPaginationArray = (totalSize, sizePerPage) => {
  const noOfpages = Math.ceil(totalSize / sizePerPage);
  const array = [];
  let pageNo = 1;
  while (pageNo <= noOfpages) {
    array.push(pageNo);
    pageNo = pageNo + 1;
  }
  return array;
};

//===============================
// Date formatter
//=======================
export const getDate = (date) => {
  return moment(date).format("DD-MM-YYYY");
};

export const getFullDate = (date) => {
  return moment(date).format("LL, HH:mm");
};

export const getDateWithTime = (date) => {
  return moment(date).format("LL, HH:mm");
};

export const getValidFormatter = (promotion) => {
  return promotion.validBoolean ? "Yes" : "No";
};

export const getBooleanFormatter = (value) => {
  return value ? "Yes" : "No";
};

export const getActiveInactive = (value) => {
  return value ? "Active" : "Inactive";
};

export const capitalLetter = (someString) => {
  if (!someString) return;
  return someString.charAt(0).toUpperCase() + someString.slice(1);
};

export const unifiedTableClass = () => {
  return "py-2 align-middle";
};

export const formatMoney = (money) => {
  let sum = 0;
  if (money) {
    sum = money;
  }

  return sum.toFixed(2) + " EUR";
};

export const languages = () => {
  return {
    lv: "LV",
    lt: "LT",
    ee: "EE",
  };
};

export const getJobStatuses = () => {
  return [
    { name: "", label: "All bookings" },
    { name: "created", label: "Created" },
    { name: "accepted", label: "Accepted" },
    { name: "costEstimated", label: "Cost estimated" },
    { name: "paid", label: "Paid" },
    { name: "completed", label: "Completed" },
    { name: "canceled", label: "Cancelled" },
  ];
};

export const getDurationTypes = () => {
  return [
    {
      name: "forever",
      label: "Forever",
    },
    {
      name: "once",
      label: "Once",
    },
    {
      name: "multiple_months",
      label: "Multiple months",
    },
  ];
};

export const getRedemptionFormatter = (promotion) => {
  const max = getMaxRedemptions(promotion);

  return (
    <p className="m-0 p-0">
      {promotion.redemption} / {max}
    </p>
  );
};

export const getMaxRedemptions = (promotion) => {
  const infinity = <FontAwesomeIcon icon={faInfinity} className="ml-1 fs--2" />;
  const max =
    promotion.maxRedemptions !== null ? promotion.maxRedemptions : infinity;

  return max;
};

export const getPaymentValue = (promotionValue) => {
  let val = "-";
  if (promotionValue) {
    val = promotionValue;
  }

  return val;
};

export const getExpiresFormatter = (date) => {
  const infinity = <FontAwesomeIcon icon={faInfinity} className="ml-1 fs--2" />;

  if (!date) {
    return infinity;
  }
  return getFullDate(date);
};

export const getTermsFormatter = (promotion) => {
  const duration = getDurationTypes().find((type) => {
    return type.name === promotion.duration;
  });
  let durationLabel = "";
  if (duration) {
    durationLabel = duration.label;
  }

  if (promotion.duration === "multiple_months") {
    durationLabel = " for " + promotion.durationValue + " months";
  }

  let termsValue = "";
  if (promotion.termsValue) {
    termsValue = promotion.termsValue;
  }

  return `${termsValue} ${
    promotion.terms === "percentage" ? "%" : "Eur"
  } off ${durationLabel}`;
};

export const getTermsLabel = (promotion) => {
  const duration = getDurationTypes().find((type) => {
    return type.name === promotion.duration;
  });

  if (promotion.duration === "multiple_months") {
    return promotion.durationValue + " months";
  }

  return duration && duration.label ? duration.label : promotion.duration;
};

export const defaultPerPage = 10;

export const CountryOptions = [
  {
    value: "latvia",
    label: "Latvia",
  },
  {
    value: "estonia",
    label: "Estonia",
  },
  {
    value: "lithuania",
    label: "Lithuania",
  },
];

export function getCountryLabelFromValue(value) {
  let countryLabel = value;
  if (value) {
    const country = CountryOptions.find((opt) => opt.value === value);
    if (country) {
      countryLabel = country.label;
    }
  }

  return countryLabel;
}

export const ArticleTypes = [
  {
    value: "blog",
    label: "Blog",
  },
  {
    value: "news",
    label: "News",
  },
];

export const BasicYesNoFields = [
  {
    value: true,
    label: "Yes",
  },
  {
    value: false,
    label: "No",
  },
];

export const LanguageOptions = [
  {
    value: "lv",
    label: "Latvian",
  },
  {
    value: "ee",
    label: "Estonian",
  },
  {
    value: "lt",
    label: "Lithuanian",
  },
  {
    value: "en",
    label: "English",
  },
  {
    value: "ru",
    label: "Russian",
  },
];

export function getStringFromArray(arr) {
  let result = "";
  if (isIterableArray(arr)) {
    arr.map((item) => {
      return (result += item + ", ");
    });

    result = result.replace(/,\s*$/, ""); //remove last comma from string
  }

  return result;
}

export function checkIfExistsTranslation(experience, experiences) {
  return experiences.find(
    (exp) =>
      exp.language === experience.language && exp.name !== experience.name
  );
}

export function getLanguageLabelString(language) {
  const obj = LanguageOptions.find((lang) => lang.value === language);
  if (obj && obj.label) {
    return obj.label;
  }
  return language;
}

export function getServicesLabelString(categories) {
  let str = "";
  categories.map((cat) => {
    if (cat.jobCategoryId && cat.jobCategoryId.categoryName) {
      return (str += cat.jobCategoryId.categoryName + ", ");
    }

    return "";
  });

  str = str.replace(/,\s*$/, ""); //remove last comma from string

  return str;
}

export function filterData(searchText, array, columns) {
  let preprared = [];
  if (isIterableArray(array)) {
    preprared = array.filter((row) => {
      const filteredByColumns = columns.filter((col) => {
        if (typeof row[col] === "undefined") {
          return false;
        }
        let st = "";
        if (searchText && row[col]) {
          st = searchText.toLowerCase();
          return row[col].toLowerCase().includes(st);
        }

        return row[col];
      });

      return filteredByColumns.length > 0;
      // return row.firstName.toLowerCase()
      //     .includes(searchText.toLowerCase()) || row.lastName.toLowerCase()
      //     .includes(searchText.toLowerCase())
    });
  }

  return preprared;
}

export const getTrailerType = (type) => {
  if (type === "trailerWithoutCover") {
    return "Trailer without cover";
  }

  return "Trailer with cover";
};

export const capitalize = (str) => {
  if (str) {
    return (str.charAt(0).toUpperCase() + str.slice(1)).replace(/-/g, " ");
  } else {
    return "N/A";
  }
};

export const fromCamelCaseToString = (text) => {
  const result = text.replace(/([A-Z])/g, " $1");
  const finalResult = result.charAt(0).toUpperCase() + result.slice(1);

  return finalResult;
};

export const badgeFormatter = (status) => {
  let color = "";
  let icon = "";
  let text = "";

  switch (status) {
    case "created":
      color = "posted";
      icon = "check";
      text = "Created";
      break;
    case "accepted":
      color = "applied";
      icon = "check";
      text = "Accepted";
      break;
    case "costEstimated":
      color = "pending";
      icon = "check";
      text = "Cost Estimated";
      break;
    case "paid":
      color = "confirmed";
      icon = "check";
      text = "Paid";
      break;
    case "completed":
      color = "completed";
      icon = "check";
      text = "Completed";
      break;
    case "cancelled":
      color = "canceled";
      icon = "ban";
      text = "Cancelled";
      break;

    default:
      color = "warning";
      icon = "stream";
      text = status;
  }

  return (
    <Badge color={`${color}`} className="rounded-capsule fs--1">
      {text}
      <FontAwesomeIcon icon={icon} transform="shrink-2" className="ml-1" />
    </Badge>
  );
};
