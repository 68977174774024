import "react-app-polyfill/ie9";
import "react-app-polyfill/stable";

import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import Main from "./Main";
import "./helpers/initFA";
import { applyMiddleware, createStore } from "redux";
import { Provider } from "react-redux";
import reducers from "./reducers";
import ReduxThunk from "redux-thunk";

ReactDOM.render(
  <Provider store={createStore(reducers, applyMiddleware(ReduxThunk))}>
    <Main>
      <App />
    </Main>
  </Provider>,
  document.getElementById("main")
);
