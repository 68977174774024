import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import { Button, Form, FormGroup, Input, Label } from "reactstrap";
import withRedirect from "../../hoc/withRedirect";
import LoginService from "../../services/login";
import { LoginContext } from "../../context/Context";

const LoginForm = ({ setRedirect, hasLabel, redirectHome }) => {
  // State
  const [isDisabled, setIsDisabled] = useState(true);
  const { email, setEmail, password, setPassword } = useContext(LoginContext);

  useEffect(() => {
    setIsDisabled(!email || !password);
  }, [email, password, setRedirect]);

  // Handler
  const handleSubmit = async (e) => {
    e.preventDefault();

    const authHandler = async () => {
      try {
        const userData = await LoginService.login({ email, password });
        if (userData.data.accessToken) {
          toast.success(`Logged  in as ${email}`);
          redirectHome();
        }
      } catch (err) {
        toast.error(`Authentication failed`);
      }
    };

    await authHandler(setRedirect);
  };

  return (
    <Form onSubmit={handleSubmit}>
      <FormGroup>
        {hasLabel && <Label>Email address</Label>}
        <Input
          placeholder={!hasLabel ? "Email address" : ""}
          value={email}
          autoComplete="off"
          onChange={({ target }) => setEmail(target.value)}
          type="email"
        />
      </FormGroup>
      <FormGroup>
        {hasLabel && <Label>Password</Label>}
        <Input
          placeholder={!hasLabel ? "Password" : ""}
          value={password}
          autoComplete="off"
          onChange={({ target }) => setPassword(target.value)}
          type="password"
        />
      </FormGroup>
      <FormGroup>
        <Button color="primary" block className="mt-3" disabled={isDisabled}>
          Log in
        </Button>
      </FormGroup>
    </Form>
  );
};

LoginForm.propTypes = {
  setRedirect: PropTypes.func.isRequired,
  layout: PropTypes.string,
  hasLabel: PropTypes.bool,
};

LoginForm.defaultProps = {
  layout: "basic",
  hasLabel: false,
};

export default withRedirect(LoginForm);
