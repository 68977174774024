import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Badge } from "reactstrap";

const NavbarVerticalMenuItem = ({ route }) => {
  return (
    <div className="d-flex align-items-center">
      {route.icon && (
        <span className="nav-link-icon">
          <FontAwesomeIcon icon={route.icon} />
        </span>
      )}
      <span>{route.name}</span>
      {!!route.badge && (
        <Badge
          color={route.badge.color || "soft-success"}
          pill
          className="ml-2"
        >
          {route.badge.text}
        </Badge>
      )}
    </div>
  );
};

export default NavbarVerticalMenuItem;
