import React, { Fragment, useContext, useState } from "react";
import { Card, CardBody, CustomInput, Input, Form } from "reactstrap";
import CountUp from "react-countup";
import CardSummary from "./CardSummary";
import FalconCardHeader from "../common/FalconCardHeader";
import loadable from "@loadable/component";
import useJobsFetch from "../../hooks/useJobsFetch";
import { LoginContext } from "../../context/Context";
import Loader from "../common/Loader";
import { returnFilterMatches } from "../../helpers/filterHelper";

const PurchasesTable = loadable(() => import("./PurchasesTable"));

function Dashboard() {
  const { userData } = useContext(LoginContext);
  const [searchPeople, setSearchPeople] = useState("");
  const [category, setCategory] = useState("");

  const jobs = useJobsFetch(userData);

  let categories = [{label:"All bookings", name:""}];

  let activeBookingsCount = 0;
  let completedBookingsCount = 0;
  let posterTotalSum = 0;

  if (typeof jobs !== "undefined" && jobs.data.jobs) {
    jobs.data.jobs.map((job) => {
      if (["accepted", "costEstimated"].indexOf(job.status) >= 0) {
        activeBookingsCount++;
      }
      if (["completed"].indexOf(job.status) >= 0) {
        completedBookingsCount++;
      }
      posterTotalSum += job.paymentInfo.posterTotalSum;

      jobs.data.jobs.forEach((job) => {
        categories.push({
          name: job.jobCategory?.categoryName,
          label: job.jobCategory?.categoryName,
        });
      });
    });
  }

  categories = categories.reduce((categoryArrayParsed, o) => {
    if (!categoryArrayParsed.some((obj) => obj.label === o.label))
      categoryArrayParsed.push(o);
    return categoryArrayParsed;
  }, []);

  let filteredJobsTemp = [];
  if (typeof jobs !== "undefined" && jobs.data.jobs) {
    filteredJobsTemp = jobs.data.jobs.filter(job => {
      if (["created", "accepted", "costEstimated", "paid"].indexOf(job.status) >= 0) {
        return returnFilterMatches(job, searchPeople);
      }
    });

    filteredJobsTemp = filteredJobsTemp.filter(job => {
      if (!job.jobCategory) {
        return false;
      }

      return job.jobCategory.categoryName.match(new RegExp(category, "i"));
    });
  }

  return (
    <Fragment>
      <div className="card-deck">
        <CardSummary title="Active bookings" color="warning">
          {jobs.loading === true ? (
            0
          ) : (
            <CountUp end={activeBookingsCount} duration={3} decimal="." />
          )}
        </CardSummary>
        <CardSummary title="Completed bookings" color="info">
          {jobs.loading === true ? (
            0
          ) : (
            <CountUp end={completedBookingsCount} duration={3} decimal="." />
          )}
        </CardSummary>
        <CardSummary title="Gross volume" color="success">
          {jobs.loading === true ? (
            0
          ) : (
            <CountUp
              end={posterTotalSum}
              duration={1}
              decimals={2}
              decimal="."
            />
          )}{" "}
          EUR
        </CardSummary>
      </div>

      <Card className="mb-3">
        <FalconCardHeader title="Active bookings" light={false}>
          <Form inline>
            <Input
              bsSize="sm"
              placeholder="Search..."
              onChange={el => setSearchPeople(el.target.value)}
            />
          </Form>
        </FalconCardHeader>
        <CardBody className="p-0">
          {jobs.loading ? (
            <Loader />
          ) : (
            <PurchasesTable jobs={filteredJobsTemp} />
          )}
        </CardBody>
      </Card>
    </Fragment>
  );
}

export default Dashboard;
