import { useEffect, useState } from "react";
import { APIURL } from "../config";
import PartlyApi from "../services/PartlyApi";

const useJobsFetch = (userData) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await PartlyApi.get(APIURL + "/admin/jobs?perPage=2000");

        setData(response.data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    };

    fetchData();
  }, [userData]);

  return { loading, setLoading, data, setData };
};

export default useJobsFetch;
