import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect
} from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { CloseButton, Fade } from "../components/common/Toast";

import DashboardLayout from "./DashboardLayout";
import ErrorLayout from "./ErrorLayout";
import AuthBasicLayout from "./AuthBasicLayout";

import loadable from "@loadable/component";
import Logout from "../components/auth/card/Logout";
const Landing = loadable(() => import("../components/landing/Landing"));

const getUserData = () => {
  // validateToken();
  const token = localStorage.getItem("token");

  return token;
};

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      getUserData() ? <Component {...props} /> : <Redirect to="/login" />
    }
  />
);

const LoginRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      !localStorage.getItem("token") ? (
        <Component {...props} />
      ) : (
        <Redirect to="/" />
      )
    }
  />
);

const LogoutRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props => {
      localStorage.setItem("token", "");
      return <Redirect to="/login" />;
    }}
  />
);

const Layout = () => {
  return (
    <Router fallback={<span />}>
      <Switch>
        <Route path="/landing" exact component={Landing} />
        <Route path="/errors" component={ErrorLayout} />
        <LoginRoute path="/login" component={AuthBasicLayout} />
        <LogoutRoute path="/logout" component={Logout} />
        <PrivateRoute component={DashboardLayout} />
      </Switch>
      <ToastContainer
        transition={Fade}
        closeButton={<CloseButton />}
        position={toast.POSITION.BOTTOM_LEFT}
      />
    </Router>
  );
};

export default Layout;
