import axios from "axios";
import { DEFAULT_LOCALE } from "../config";

export default class PartlyApi {
  static async get(url, localeOverride = null) {
    const token = localStorage.getItem("token");
    const locale = localeOverride || localStorage.getItem("locale-admin");

    const response = await axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
          "X-Requested-With": "XMLHttpRequest",
          locale: locale || DEFAULT_LOCALE,
        },
      })
      .catch((e) => {
        logoutRule(e);
      });

    return response;
  }

  static async create(url, data, localeOverride = null) {
    const token = localStorage.getItem("token");
    const locale = localeOverride || localStorage.getItem("locale-admin");

    const response = await axios
      .post(url, data, {
        headers: {
          Authorization: `Bearer ${token}`,
          "X-Requested-With": "XMLHttpRequest",
          locale: locale || DEFAULT_LOCALE,
        },
      })
      .catch((e) => {
        logoutRule(e);
      });

    return response;
  }

  static async put(url, data, localeOverride = null) {
    const token = localStorage.getItem("token");
    const locale = localeOverride || localStorage.getItem("locale-admin");

    const response = await axios
      .put(url, data, {
        headers: {
          Authorization: `Bearer ${token}`,
          "X-Requested-With": "XMLHttpRequest",
          locale: locale || DEFAULT_LOCALE,
        },
      })
      .catch((e) => {
        logoutRule(e);
      });

    return response;
  }

  static async delete(url, localeOverride = null) {
    const token = localStorage.getItem("token");
    const locale = localeOverride || localStorage.getItem("locale-admin");

    const response = await axios
      .delete(url, {
        headers: {
          Authorization: `Bearer ${token}`,
          "X-Requested-With": "XMLHttpRequest",
          locale: locale || DEFAULT_LOCALE,
        },
      })
      .catch((e) => {
        logoutRule(e);
      });

    return response;
  }

  static async createOrUpdate(url, data, localeOverride = null) {
    if (data._id) {
      return await this.put(url, data, localeOverride);
    }

    return await this.create(url, data, localeOverride);
  }
}

function logoutRule(e) {
  if (e.response.status === 403 || e.response.status === 401) {
    window.location.href = "/logout";
  }
}
