import { handleActions } from "redux-actions";
import { requestJobs, jobsRequestSuccess, jobsRequestFailed } from "../actions";

const initialState = {
  jobs: [],
  requestInProgress: false,
  error: null
};

const jobsReducer = handleActions(
  {
    [requestJobs]: state => ({
      ...state,
      requestInProgress: true
    }),
    [jobsRequestSuccess]: (state, action) => ({
      ...state,
      jobs: action.payload,
      ...action.payload,
      requestInProgress: false
    }),
    [jobsRequestFailed]: (state, action) => ({
      ...state,
      error: action.payload,
      requestInProgress: false
    })
  },
  initialState
);

export default jobsReducer;
