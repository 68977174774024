import { createContext } from "react";

const AppContext = createContext({
  isFluid: false,
  isRTL: false,
  isDark: false,
  showBurgerMenu: false, // controls showing vertical nav on mobile
  currency: "$",
  isLoggedIn: false,
  navbarFixed: true,
});

export const UserDataContext = createContext({
  userData: [],
});

export const EmailContext = createContext({ emails: [] });

export const LoginContext = createContext({
  setEmail: () => {},
  setPassword: () => {},
  email: "",
  password: "",
});

export const LayoutContext = createContext({
  countriesArr: [],
});

export const CategoriesContext = createContext({
  data: ["321", "333"],
});

export const KanbanContext = createContext({
  KanbanColumns: [],
  kanbanTasks: [],
  steps: [],
});

export const FlowsContext = createContext({
  categoriesList: [],
  flowsList: [],
  refreshFlows: false,
});

export const ProductContext = createContext({ products: [] });
export const BookingContext = createContext({ booking: {} });
export const ShowBookingMenu = createContext({ booking: 0 });
export const FeedContext = createContext({ feeds: [] });

export default AppContext;
