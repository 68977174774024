import React, { Fragment } from "react";
import LoginForm from "../LoginForm";
import withRedirect from "../../../hoc/withRedirect";

const Login = ({ params }) => {
  function redirectHome() {
    params.history.push("/");
  }

  return (
    <div>
      <Fragment>
        <LoginForm params={params} redirectHome={redirectHome} />
      </Fragment>
    </div>
  );
};

export default withRedirect(Login);
