import {
  faCity,
  faBook,
  faCreditCard,
  faMoneyCheckAlt,
  faPencilAlt,
  faTable,
  faTag,
  faUser,
  faWrench,
  faBriefcase
} from "@fortawesome/free-solid-svg-icons";
import { faList } from "@fortawesome/free-solid-svg-icons/faList";

export const homeRoutes = {
  name: "Home",
  to: "/",
  exact: true,
  icon: "home"
};

export const bookings = {
  name: "Bookings",
  to: "/bookings",
  exact: true,
  icon: faBook
};

export const partners = {
  name: "Partners",
  to: "/partners",
  icon: faBriefcase
};

export const users = {
  name: "Users",
  to: "/users",
  exact: true,
  icon: faUser,
  children: [
    { to: "/users/customer", exact: true, name: "Customer" },
    { to: "/users/service-providers", exact: true, name: "Service providers" }
  ]
};

export const promotionRoutes = {
  name: "Promotions",
  to: "/promotions",
  exact: true,
  icon: faTag
};

export const billingRoutes = {
  name: "Billings",
  to: "/billings",
  exact: true,
  icon: faCreditCard
};

export const categoriesRoutes = {
  name: "Categories",
  to: "/categories",
  exact: false,
  icon: faTable
};

export const citiesRoutes = {
  name: "Cities",
  to: "/cities",
  exact: false,
  icon: faCity
};

export const myTasks = {
  name: "My tasks",
  to: "/my-tasks",
  // exact: true,
  icon: "list-ul"
};

export const login = {
  name: "Login",
  to: "/login",
  // exact: true,
  icon: "home"
};

export const logout = {
  name: "Logout",
  to: "/logout",
  // exact: true,
  icon: "home"
};

export const linkedAccounts = {
  name: "Linked accounts",
  to: "/linked-accounts",
  // exact: true,
  icon: "users"
};

export const payments = {
  name: "Payments",
  to: "/payments",
  // exact: true,
  icon: faMoneyCheckAlt
};

export const reviews = {
  name: "Reviews",
  to: "/reviews",
  // exact: true,
  icon: "star-half-alt"
};

export const settings = {
  name: "Settings",
  to: "/pages/settings",
  // exact: true,
  icon: faWrench
};

export const articles = {
  name: "Articles",
  to: "/articles",
  // exact: true,
  icon: faPencilAlt
};

export const sections = {
  name: "Sections",
  to: "/sections",
  // exact: true,
  icon: faPencilAlt
};

export const authenticationRoutes = {
  name: "Authentication",
  to: "/authentication",
  icon: "lock",
  children: [
    {
      to: "/authentication/basic",
      name: "Basic",
      children: [
        { to: "/authentication/basic/login", name: "Login" },
        { to: "/authentication/basic/logout", name: "Logout" },
        { to: "/authentication/basic/register", name: "Register" },
        {
          to: "/authentication/basic/forget-password",
          name: "Forgot password"
        },
        { to: "/authentication/basic/password-reset", name: "Reset password" },
        { to: "/authentication/basic/confirm-mail", name: "Confirm mail" },
        { to: "/authentication/basic/lock-screen", name: "Lock screen" }
      ]
    },
    {
      to: "/authentication/card",
      name: "Card",
      children: [
        { to: "/authentication/card/login", name: "Login" },
        { to: "/authentication/card/logout", name: "Logout" },
        { to: "/authentication/card/register", name: "Register" },
        { to: "/authentication/card/forget-password", name: "Forgot password" },
        { to: "/authentication/card/password-reset", name: "Reset password" },
        { to: "/authentication/card/confirm-mail", name: "Confirm mail" },
        { to: "/authentication/card/lock-screen", name: "Lock screen" }
      ]
    },
    {
      to: "/authentication/split",
      name: "Split",
      children: [
        { to: "/authentication/split/login", name: "Login" },
        { to: "/authentication/split/logout", name: "Logout" },
        { to: "/authentication/split/register", name: "Register" },
        {
          to: "/authentication/split/forget-password",
          name: "Forgot password"
        },
        { to: "/authentication/split/password-reset", name: "Reset password" },
        { to: "/authentication/split/confirm-mail", name: "Confirm mail" },
        { to: "/authentication/split/lock-screen", name: "Lock screen" }
      ]
    }
  ]
};

export const emailRoutes = {
  name: "Email",
  to: "/email",
  icon: "envelope-open"
};

export const componentRoutes = {
  name: "Components",
  to: "/components",
  icon: "puzzle-piece",
  children: [
    { to: "/components/alerts", name: "Alerts" },
    { to: "/components/accordions", name: "Accordions" },
    { to: "/components/avatar", name: "Avatar" },
    { to: "/components/badges", name: "Badges" },
    { to: "/components/backgrounds", name: "Backgrounds" },
    { to: "/components/breadcrumb", name: "Breadcrumb" },
    { to: "/components/buttons", name: "Buttons" },
    { to: "/components/cards", name: "Cards" },
    { to: "/components/collapses", name: "Collapses" },
    { to: "/components/dropdowns", name: "Dropdowns" },
    { to: "/components/forms", name: "Forms" },
    { to: "/components/listgroups", name: "List groups" },
    { to: "/components/modals", name: "Modals" },
    { to: "/components/navs", name: "Navs" },
    { to: "/components/navbars", name: "Navbars" },
    { to: "/components/pageheaders", name: "Page headers" },
    { to: "/components/paginations", name: "Paginations" },
    { to: "/components/popovers", name: "Popovers" },
    { to: "/components/progress", name: "Progress" },
    { to: "/components/tables", name: "Tables" },
    { to: "/components/tooltips", name: "Tooltips" }
  ]
};

export const pluginRoutes = {
  name: "Plugins",
  to: "/plugins",
  icon: "plug",
  children: [
    { to: "/plugins/bulk-select", name: "Bulk select" },
    { to: "/plugins/chart", name: "Chart" },
    { to: "/plugins/countup", name: "Countup" },
    { to: "/plugins/datetime", name: "Datetime" },
    { to: "/plugins/echarts", name: "Echarts" },
    { to: "/plugins/fontawesome", name: "Fontawesome" },
    { to: "/plugins/google-map", name: "Google map" },
    { to: "/plugins/image-lightbox", name: "Image lightbox" },
    { to: "/plugins/plyr", name: "Plyr" },
    { to: "/plugins/progressbar", name: "Progressbar" },
    { to: "/plugins/select", name: "Select" },
    { to: "/plugins/slick-carousel", name: "Slick Carousel" },
    { to: "/plugins/toastify", name: "Toastify" },
    { to: "/plugins/typed", name: "Typed" },
    { to: "/plugins/wysiwyg", name: "WYSIWYG editor" }
  ]
};

export const utilityRoutes = {
  name: "Utilities",
  to: "/utilities",
  icon: ["fab", "hotjar"],
  children: [
    { to: "/utilities/borders", name: "Borders" },
    { to: "/utilities/clearfix", name: "Clearfix" },
    { to: "/utilities/closeIcon", name: "Close icon" },
    { to: "/utilities/colors", name: "Colors" },
    { to: "/utilities/display", name: "Display" },
    { to: "/utilities/embed", name: "Embed" },
    { to: "/utilities/figures", name: "Figures" },
    { to: "/utilities/flex", name: "Flex" },
    { to: "/utilities/grid", name: "Grid" },
    { to: "/utilities/sizing", name: "Sizing" },
    { to: "/utilities/spacing", name: "Spacing" },
    { to: "/utilities/stretchedLink", name: "Stretched link" },
    { to: "/utilities/typography", name: "Typography" },
    { to: "/utilities/verticalAlign", name: "Vertical align" },
    { to: "/utilities/visibility", name: "Visibility" }
  ]
};

export default [
  homeRoutes,
  bookings,
  users,
  partners,
  citiesRoutes,
  categoriesRoutes,
  promotionRoutes,
  billingRoutes
];
