import React, { useContext, useEffect } from "react";
import PropTypes from "prop-types";
import { Route, Switch } from "react-router-dom";
import Dashboard from "../components/dashboard/Dashboard";
import NavbarTop from "../components/navbar/NavbarTop";
import NavbarVertical from "../components/navbar/NavbarVertical";
import Footer from "../components/footer/Footer";
import loadable from "@loadable/component";

import AppContext, { LayoutContext } from "../context/Context";
import useCountriesFetch from "../hooks/useCountriesFetch";

const DashboardRoutes = loadable(() => import("./DashboardRoutes"));

const DashboardLayout = ({ location }) => {
  const { isFluid } = useContext(AppContext);

  useEffect(() => {
    DashboardRoutes.preload();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  let countriesArr = [];
  const countriesFetch = useCountriesFetch();
  if (countriesFetch.loading === false) {
    countriesArr = countriesFetch.data;
  }
  const userData = [];

  return (
    <LayoutContext.Provider value={{ countriesArr, userData }}>
      <div className={isFluid ? "container-fluid" : "container"}>
        <NavbarVertical />
        <div className="content">
          <NavbarTop />
          <Switch>
            <Route path="/" exact component={Dashboard} />
            {/*<Route path="/dashboard-alt" exact component={DashboardAlt} />*/}
            <DashboardRoutes />
          </Switch>
          <Footer />
        </div>
      </div>
    </LayoutContext.Provider>
  );
};

DashboardLayout.propTypes = { location: PropTypes.object.isRequired };

export default DashboardLayout;
